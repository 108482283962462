import React from 'react'
import { getCredit } from "../../utils/calculations";
import { ServicesContext } from "../ServicesProvider";
import asyncPool from "tiny-async-pool";
import { useUser } from "../../hooks";

const defaultValues = {}

export const BillingContext = React.createContext(defaultValues)

export const BillingProvider = ({ children }) => {
    const { userService } = React.useContext(ServicesContext)
    const { refreshBalance } = useUser()

  const getUsersBilling = () => userService.getUsersBilling()

    const getProfit = users => users?.reduce(
        (t, user) => (t += parseFloat(getCredit(user).profit)),
        0
      );

    const getTotal = users => users?.reduce((t, c) => (t += (c.billing || {}).profit || 0), 0);

    const getTotalPending = users =>
    users?.reduce(
      (t, user) => (t += parseFloat(getCredit(user).amount)),
      0
    );

  const getTotalPaid = users =>
    users?.reduce(
      (t, user) => t += parseFloat(getCredit(user).paid || 0),
      0
    );

    const getTotalPaidGross = users =>
        users?.reduce(
        (t, user) => t += parseFloat(getCredit(user).grossPayments || 0),
        0
        );

    const compareAndSetPendingPayments = (users) => {
        const currentTotal = getTotal(users);
        if (currentTotal === 0) return 0;
        const previousTotal = parseFloat(localStorage.getItem("previousTotalUser"), 10) || currentTotal;
        const diff = getTotal(users) - previousTotal;
        localStorage.setItem("previousTotalUser", currentTotal);
        if (diff === 0) return (parseFloat(localStorage.getItem("diffUser"), 10) || 0).toFixed(2);
        localStorage.setItem("diffUser", diff);
        return diff;
    };


    const getPaidRaspberries = (validUsers) => validUsers?.reduce(
        (t, user) => {
            const isRaspberryBeingUsed = ["tunnel"].includes((user.configuration.proxy || {}).mode);
            if (!isRaspberryBeingUsed) return t;
            const isPaid = getCredit(user).isRaspberryPaid;
            return { paid: isPaid ? t.paid + 1 : t.paid, total: t.total + 1 };
        },
        { paid: 0, total: 0 }
    );

    const getPendingPaymentsToConfirm = (user) => getCredit(user).payments.filter(({ status }) => status !== 1) ;

    const getTotalToConfirm = (validUsers) =>
        validUsers?.reduce(
            (t, user) => (t += getCredit(user).toConfirm ),
            0
        );

    const refreshBalances = async (validUsers) => {
        await asyncPool(3, validUsers, async (user) =>
            refreshBalance(user._id.email)
        );
    };

    return (
        <BillingContext.Provider value={{
            getTotal,
            getTotalPending,
            getTotalPaid,
            getProfit,
            compareAndSetPendingPayments,
            getUsersBilling,
            getPaidRaspberries,
            getPendingPaymentsToConfirm,
            getTotalToConfirm,
            refreshBalances,
            getTotalPaidGross
        }}>
            {children}
        </BillingContext.Provider>
    )
}
