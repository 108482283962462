import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  background-color: #111E12; // Adjust based on the design background color
  flex-direction: column;
  
  min-height: 100vh;
  padding: 20px;
`;

export const Header = styled.header`
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: auto;
  margin-top: 0px;
  `;

export const Logo = styled.h1`
  font-size: 2em;
  color: #fff;
  margin-right: 20px;

  img {
    height: 60px;
  }

  @media (max-width: 768px) {
    img {
      height: 30px; // Adjust this value as needed
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 5px;

  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export const NavBarLink = styled.a`
  color: white;
  font-family: 'Montserrat';
  margin-bottom: 0;
  letter-spacing: -0.04em;
  font-size: 18px;

  :hover {
    color: #1BB78A;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  align-items: center; // Center vertically
  height: 100%;

  margin-top: 20px; 
`;

export const WidgetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 850px) and (max-width: 1400px) {
    max-width: 1000px;
    justify-content: center;
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const SubscriptionCard = styled.div`
  width: 100%;
  padding: 20px;
  background: rgb(29, 45, 29);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  height: 450px;

  @media (min-width: 850px) and (max-width: 1400px) {
    width: 100%;
    max-width: 100%;
    max-height: 290px;
  }
`;

export const SubscriptionCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  margin-bottom: 60px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;

`;

export const Button = styled.button`
  background-color: #22c55e; // Green button
  color: white;
  border: none;
  padding: 10px 15px;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 4px;
`;

export const AccessButton = styled.a`
  background-color: #D9D9D9;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;

  background: #111E12;
  color: white;
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;

  :hover {
    color: #E9FD8C;
  }
`;

export const EventsParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;

  @media (max-width: 1400px) {
      width: 100%;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const EventsContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  background: rgb(29,45,29);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  height: 450px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 850px) and (max-width: 1400px) {
    height: unset;
    align-self: stretch;
    max-height: 590px;
    max-width: 100%;
  }

  @media (max-width: 850px) {
    height: 100%;
  }
`;

export const EventsSubcontainer = styled.div`

`;

export const Subtitle = styled.h3`
  margin-bottom: 20px;
  font-size: 2rem;
`;

export const EventList = styled.div`
  list-style: none;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const EventsItem = styled.div`
  font-family: 'Montserrat';
  font-weight: normal;
  display: flex; // Add this
  align-items: center; // Add this
  line-height: 0.8;
  gap: 10px;
  background-color: #111E12;
  padding: 10px;
  border-radius: 10px;
  height: 125px;
  width: 100%;
  min-width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  animation: appear 0.5s ease-in-out;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const EventsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: flex-start;
  height: 40px;
`;

export const SportName = styled.h4`
  margin-bottom: 0;
  font-size: 17px;
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  word-break: break-word;
`;

export const EventCountText = styled.h4`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 30px;
  font-family: 'Inter';
`;

export const InvoiceList = styled.div`
  margin-top: 20px;
`;

export const SubscriptionTitle = styled.h3`
  font-size: 2rem;
  color: white;
`;

export const PurchaseTitle = styled.h3`
  font-size: 2rem;
  color: #111E12;
`;

export const SubscriptionSubtitle = styled.h3`
  font-size: 30px;
  font-family: 'Inter';
  font-weight: normal;
  margin-bottom: 10px;
  color: #758b77;
  letter-spacing: -0.05em;
  text-align: center;
  
`;

export const SubscriptionStatus = styled.h3`
  font-size: 25px;
  font-family: 'Montserrat';
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.99);
  letter-spacing: -0.05em;
  text-align: center;
`;

export const CancelButton = styled.button`
  background-color: #111E12; // Green button
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  height: 50px;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const FreezeButton = styled.button`
  background-color: #B8DCEF;
  color: #0E2123;
  border: none;
  padding: 10 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  height: 50px;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #2e829b;
  }
`;

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`
export const ModalButton = styled.button`
  background-color: white; // Green button
  color: #111E12;
  border: none;
  padding: 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const SubscriptionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1em;
`;

export const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1em;

  @media (min-width: 850px) and (max-width: 1400px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const PurchaseCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 1em;
  background: #E9FD8C;
  color: white;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  height: 450px;
  padding: 20px;

  @media (min-width: 850px) and (max-width: 1400px) {
    width: 100%;
    max-width: 100%;
    max-height: 350px;
  }

  @media (max-width: 850px) {
    height: 100%;
  }
`;

export const PurchaseSubcontainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
  max-width: 450px; // Adjust width as needed
  height: 100%;


`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PriceColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  color: #1d2d1d;
  width: 200px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const PriceText = styled.h1`
  font-size: 30px;
  font-family: 'Inter';
  font-weight: normal;
  margin-bottom: 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1em;
`;

export const RowPrice = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 1em;
  width: 100%;
  background-color: #111E12;
  padding: 10px;
  border-radius: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  width: 100%;
  max-width: 450px; // Adjust width as needed
`;

export const CouponContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 75px;

  @media (max-width: 500px) {
    height: 100%;
  }
`;

export const CouponSubcontainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  height: 50px;
  justify-content: space-around;

  @media (max-width: 500px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 300px;
  gap: 0px;

  @media (max-width: 850px) {
    width: 100%;
  }

  #dropdown-basic-button {
    height: 100%;
    background: #E9FD8C;
    color: #1d2d1d;
    border-radius: 0px 5px 5px 0px;

    :hover, :focus, :active, :focus-visible {
      background-color: #007053;
      color: #E9FD8C;
    }
  }

  .dropdown-menu {
    background-color: #111E12;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border: none;
    width: 350px;

    @media (max-width: 500px) {
      width: 300px;
    }
  }

  .dropdown-item {
    background-color: #1d2d1d;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
    padding: 10px;

    &:hover {
      background-color: #007053;
      color: #E9FD8C;
    }
  }
`;

export const CouponInput = styled.input`
  flex-grow: 1;
  padding: 12px 15px;
  border: 1px solid #3a5a3c;
  border-radius: ${props => props.couponsAvailable ? '5px 0px 0px 5px' : '5px'};
  background-color: #1d2d1d;
  color: white;
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    color: #6c8c6e;
  }

  &:focus {
    outline: none;
    border-color: #E9FD8C;
  }

    @media (max-width: 500px) {
    width: 100%;
  }
`;

export const CouponButton = styled.button`
  background-color: #111E12;
  color: #E9FD8C;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007053;
    color: #E9FD8C;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: #f77777;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 3px;
`;

export const CouponSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
`;

export const CouponSelectorText = styled.p`
  margin-bottom: 0;
`;