import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Form } from 'react-bootstrap';
import { PurchaseCard, PurchaseTitle, PurchaseContainer, Row, RowPrice, Column, SubscriptionSubtitle, PurchaseSubcontainer, CouponInput, CouponButton, CouponContainer, CouponSubcontainer, PriceColumn, PriceText, ErrorMessage, InputContainer, CouponSelectorContainer, CouponSelectorText } from '../UserHome.styled';
import { formatCurrency } from '../../../utils/formatting';
import { useUser } from "../../../hooks";
import { DropdownButton, Dropdown } from 'react-bootstrap';

export const PurchaseSection = ({ selectedSubscription, availableSubscriptions, handleSelectedSubscription, handleSubscriptionAction, t, applyCoupon, couponError }) => {
    const { createSubscription } = useUser();
    const [couponCode, setCouponCode] = useState('');
    const [selectedStoredCoupon, setSelectedStoredCoupon] = useState('');
    const [storedCoupons, setStoredCoupons] = useState([]);
    const { getCoupons } = useUser();

    useEffect(() => {
        async function fetchCoupons() {
            const coupons = await getCoupons();
            setStoredCoupons(coupons);
        }
        fetchCoupons();
    }, []);

    const handleStoredCouponChange = (coupon) => {
        setSelectedStoredCoupon(coupon);
        setCouponCode(coupon);
    };

    return (
        <PurchaseCard>
            <PurchaseTitle>{t('purchaseSubscription')}</PurchaseTitle>
            <PurchaseContainer>
            <PurchaseSubcontainer>
                {selectedSubscription && (
                    <>
                    <RowPrice>
                        <PriceColumn>
                            <PriceText>
                                {formatCurrency(
                                    selectedSubscription?.billing?.amount,
                                    selectedSubscription?.billing?.currency
                                )}
                            </PriceText>
                            <p>
                                <span>{t('only')}</span>{" "}
                                {formatCurrency(
                                    selectedSubscription?.billing?.amount /
                                    selectedSubscription?.billing?.duration,
                                    selectedSubscription?.billing?.currency
                                )}
                                /{t('day')}
                            </p>
                        </PriceColumn>
                        <Column>
                            <SubscriptionSubtitle>Pro Subscription</SubscriptionSubtitle>
                            <Form.Control
                                as="select"
                                custom
                                style={{ maxWidth: 105 }}
                                onChange={handleSelectedSubscription}
                                defaultValue={selectedSubscription?.billing?.duration}
                            >
                                {availableSubscriptions.map((subscription) => (
                                    <option
                                        key={subscription?.billing?.duration}
                                        value={subscription?.billing?.duration}
                                    >
                                        {subscription?.billing?.duration} {t('days')}
                                    </option>
                                ))}
                            </Form.Control>
                        </Column>
                    </RowPrice>
                    <Row>
                    <PayPalScriptProvider
                        options={{
                            "client-id": "AacbszPIAlyVz8VanEGpC4CscWRv7Wdjjmw-qI3NuZJ-kAoV9om6rPXowYzH5y3wQywc5YKaVmLwftmO",
                            components: "buttons",
                            intent: "subscription",
                            vault: true,
                        }}
                    >
                        <PayPalButtons
                            showSpinner={true}
                            forceReRender={[
                                selectedSubscription?.billing?.paypalId,
                            ]}
                            createSubscription={async (data, actions) => {
                                const { subscriptionId } = await createSubscription();
                                const orderId = await actions.subscription.create(
                                    {
                                        plan_id: selectedSubscription?.billing?.paypalId,
                                        custom_id: subscriptionId,
                                    }
                                );
                                return orderId;
                            }}
                            onError={(err) => console.error(err)}
                            onApprove={() => handleSubscriptionAction('pending')}
                            style={{ label: "subscribe", color: "white" }}
                        />
                    </PayPalScriptProvider>
                    </Row>
                    </>
                )}
            </PurchaseSubcontainer>
            <CouponContainer>
                    {couponError && <ErrorMessage>{couponError}</ErrorMessage>}
                    <CouponSubcontainer>
                        <InputContainer>
                            <CouponInput
                                type="text"
                                placeholder={t('enterCouponCode')}
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                couponsAvailable={storedCoupons.length > 0}
                            />
                            { storedCoupons.length > 0 && <DropdownButton id="dropdown-basic-button" title="" menuAlign="right">
                                {storedCoupons.map((coupon) => (
                                    <Dropdown.Item key={coupon} value={coupon} onClick={() => handleStoredCouponChange(coupon.code)}>
                                        <CouponSelectorContainer>
                                            <CouponSelectorText>{t('couponLength', { count: coupon.durationDays })}</CouponSelectorText>
                                            <CouponSelectorText>{t('couponExpiration', { date: new Date(coupon.expirationDate).toLocaleDateString() })}</CouponSelectorText>
                                        </CouponSelectorContainer>
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                            }
                        </InputContainer>
                        <CouponButton onClick={() => applyCoupon(couponCode)}>
                            {t('applyCoupon')}
                        </CouponButton>
                    </CouponSubcontainer>
                </CouponContainer>
            </PurchaseContainer>
        </PurchaseCard>
    );
};