import styled from "styled-components";
import { Popover } from "react-bootstrap";

export const UserBoxContainer = styled.div`
  margin-top: 10px;
  padding: 20px;
  border: none;
  background-color: #192619;
  // background-color: rgb(29,45,29);
  
  border-radius: 10px;

  .settings {
    margin-left: 10px;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .totalProfit {
    height: fit-content;
    margin-top: 15px;
    width: 100%;
  }

  .Dropdown-root {
    margin-left: 5px;
  }

  .Dropdown-control {
    padding: 0px 10px 6px 10px !important;
    width: 85px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  .Dropdown-arrow {
    position: unset !important;
  }

  @media only screen and (max-width: 700px) {
    .settings {
      margin-left: 0;
    }
	  
	  .settings > button {
		  margin-top: 10px;
      margin-left: 0 !important;
	  }
	  
    .settings .dropdown-item {
      white-space: normal;
    }

    .settings .dropdown-menu {
      width: 180px;
    }

    .totalProfit {
      height: fit-content;
      width: 100%;
    }
  }

  .btn-secondary {
    background-color: #111e12 !important;
    color: white;
    border: 1.5px solid #1a2d1c;
  }

  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #1a2d1c !important;
    color: white !important;
  }
`

export const UserEmail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
		flex-direction: column;
  }
`

export const Tags = styled.div`
  display: flex;
  left: 0;
  align-items: center;
  margin-top: 5px;

  @media only screen and (max-width: 700px) {
    display: flex;
	  width: 100%;
	  margin-left: 0;
    align-items: flex-start;
	  flex-direction: column;
  }
`

export const LeftSide = styled.div`
  display: flex;
  left: 0;
  align-items: center;

  @media only screen and (max-width: 700px) {
    display: flex;
	  width: 100%;
	  margin-left: 0;
    align-items: flex-start;
	  flex-direction: column;
  }

  .dropdown-toggle {
    :hover, :focus, :active {
      cursor: pointer;
      color: #E9FD8C;
      background-color: #052D23;
      border: 1px solid #052D23;
    }
  }
`

export const UserTag = styled.div`
  margin-right: 5px;
  background: #94E5AB;
  color: #1d2d1d;
`

export const LeftSideInternal = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
	  width: 100%;
  }
`

export const EmailAndWatchBets = styled.div`
  display: flex;

  @media only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`

export const UserEmailText = styled.div`
  font-size: 25px;
  font-weight: bold;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
`

export const RightSide = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;

  @media only screen and (max-width: 700px) {
	  flex-direction: column;
	  align-self: flex-start;
  }
`

export const UserProfit = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
	  margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const UserAlerts = styled.div`
  margin-left: 10px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (max-width: 700px) {
    margin-left: 5px;
  }
`

export const UserAlertBadge = styled.div`
  margin-right: 5px;
`

export const BookiesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const BookiesHeader = styled.h3`
  text-align: left;
`

export const WatchBets = styled.a`
  margin-left: 25px;
  cursor: pointer;
  background-color: #B8DCEF;
  color: #1d2d1d;
  border-radius: 5px;
  font-weight: bold;

  @media only screen and (max-width: 700px) {
    width: 100%;
    cursor: pointer;
    font-size: smaller;
    margin-left: 0px;
  }

  :hover {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }
`

export const PaymentHistory = styled.p`
  margin-bottom: 3px;
`

export const PopoverCustom = styled(Popover)`
  background-color: #2A3B29;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  .popover-header {
    background-color: #3B4B39;
    border-radius: 10px 10px 0 0;
    color: white;
    border: 0px;
  }
`

export const UserBadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #111E12;
  border-radius: 15px;
  padding: 5px;
`