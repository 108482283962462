import React, { useState } from 'react'
import { ServicesContext } from "../ServicesProvider";
import { useAuth } from "../../hooks";

const defaultValues = {}

export const UserContext = React.createContext(defaultValues)

export const UserProvider = ({ children }) => {
    const { userService } = React.useContext(ServicesContext)
    const { bookiesService } = React.useContext(ServicesContext)
    const { modifyUser } = useAuth()
    const [ settledBets, setSettledBets ] = useState([])

    const createUser = email => userService.create(email)

    const createBotUser = data => userService.createBotUser(data)

    const getActiveSubscription = () => userService.getActiveSubscription();

    const createSubscription = () => userService.createSubscription();

    const cancelSubscription = subscriptionId => userService.cancelSubscription(subscriptionId);

    const freezeSubscription = subscriptionId => userService.freezeSubscription(subscriptionId);

    const unfreezeSubscription = subscriptionId => userService.unfreezeSubscription(subscriptionId);

    const getAvailableSubscriptions = () => userService.getAvailableSubscriptions();

    const getAddresses = () => userService.getAddresses();

    const detachProxy = body => userService.detachProxy(body);

    const getExtensionConfiguration = () => userService.getExtensionConfiguration();

    const getNotifications = () => userService.getNotifications()

    const getBookies = (email, complexMode) => userService.getBookies(email, complexMode)

    const getAllBookies = (complexMode) => bookiesService.getBookies(complexMode)

    const refreshBalance = async (email) => {
        const user = await userService.refreshBalance(email)
        modifyUser(user);
    };

    const confirmPayment = async (email, paymentId) => {
        const user = await userService.confirmPayment(email, paymentId)
        modifyUser(user);
    };

    const enableEmail = async (email) => {
        const user = await userService.enableEmail(email)
        modifyUser(user);
    };

    const disableEmail = async (email) => {
        const user = await userService.disableEmail(email)
        modifyUser(user);
    };

    const setReadyStatus = async (email, status) => {
        console.log('status', status)
        const user = await userService.setReadyStatus(email, status)
        modifyUser(user);
    };

    const addPayment = async (email, data) => {
        const user = await userService.addPayment(email, data)
        modifyUser(user)
    }

    const transferCredit = async (email, amount) => {
        const user = await userService.transferCredit(email, amount)
        modifyUser(user)
    }

    const addCorrection = async (email, data) => {
        const user = await userService.addCorrection(email, data)
        modifyUser(user)
    }

    const toggleBookieStatus = async (email, bookie, action) => {
        const user = await userService.toggleBookieStatus(email, bookie, action)
        modifyUser(user)
    }

    const markNotificationSeen = async (error) => userService.markNotificationSeen(error)

    const bookieAction = async (email, bookie, action) => userService.bookieAction(email, bookie, action)

    const settleBet = async (email, bet) => {
        const modifiedBet = { ...bet, status: 'SETTLED' };
        await userService.settleBet(email, bet)
        setSettledBets([...settledBets, modifiedBet]);
    }

    const addBookie = (obj) => userService.addBookie(obj)

    const getUserBets = email => userService.getUserBets(email);

    const getBookieCredentials = (email, bookie) => userService.getBookieCredentials(email, bookie);

    const getChapters = () => userService.getChapters();

    const getBotHome = () => userService.getBotHome();

    const getInvoices = () => userService.getInvoices();

    const getInvoice = (id) => userService.getInvoice(id);
 
    const navigateToArb = (arb) => userService.navigateToArb(arb);

    const executeArb = (arb) => userService.executeArb(arb);

    const executeValuebet = (bet) => userService.executeValuebet(bet);

    const redeemCoupon = (couponCode) => userService.redeemCoupon(couponCode);

    const redeemPoints = (points) => userService.redeemPoints(points);

    const getCoupons = () => userService.getCoupons();

    const toggleClosing = async (email, bookie, closing) => {
        const user = await userService.toggleClosing(email, bookie, closing)
        modifyUser(user)
    }

    const updateTags = async (email, tags) => {
        const user = await userService.updateTags(email, tags)
        modifyUser(user)
    }

    const queryUsers = (email) => userService.queryUsers(email)

    const removeUserFromAdministrator = (administratorEmail, email) => userService.removeUserFromAdministrator(administratorEmail, email)

    const addUserToAdministrator = (administratorEmail, email) => userService.addUserToAdministrator(administratorEmail, email)

    return (
        <UserContext.Provider value={{
            createUser,
            createBotUser,
            getActiveSubscription,
            createSubscription,
            cancelSubscription,
            freezeSubscription,
            unfreezeSubscription,
            getAvailableSubscriptions,
            getAddresses,
            detachProxy,
            getExtensionConfiguration,
            getNotifications,
            getBookies,
            getAllBookies,
            disableEmail,
            refreshBalance,
            confirmPayment,
            enableEmail,
            addPayment,
            addCorrection,
            transferCredit,
            toggleBookieStatus,
            markNotificationSeen,
            bookieAction,
            settleBet,
            addBookie,
            getUserBets,
            modifyUser,
            settledBets,
            getChapters,
            getBotHome,
            getBookieCredentials,
            setReadyStatus,
            getInvoices,
            getInvoice,
            navigateToArb,
            executeArb,
            executeValuebet,
            redeemCoupon,
            redeemPoints,
            getCoupons,
            toggleClosing,
            updateTags,
            queryUsers,
            removeUserFromAdministrator,
            addUserToAdministrator
        }}>
            {children}
        </UserContext.Provider>
    )
}
