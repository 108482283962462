import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  background-color: #192619;
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 1rem auto;
    padding: 0.5rem;
  }
`;

export const TabButton = styled.button`
  padding: 0.75rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  flex: 1;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  
  background-color: ${props => props.active ? '#E8FC8B' : '#2A3B29'};
  color: ${props => props.active ? '#1B2D1A' : '#ABCDDF'};

  &:hover {
    background-color: ${props => props.active ? '#E8FC8B' : '#B8DCEF'};
    color: ${props => props.active ? '#1B2D1A' : '#1B2D1A'};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    font-size: 0.75rem;
    max-width: none;
    height: 40px;
    min-height: 40px;
  }
`;

export const BookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  gap: 40px;
  background-color: #192619;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BookiesSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #1C2D1C;
  padding: 20px;
  border-radius: 10px;
`;

export const BookiesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const BookieHeaderText = styled.h3`
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 30px;
  color: white;
  margin: 0;
`;

export const LegendContainer = styled.div`
  .provider {
    border: solid 1px green;
    background-color: rgba(113, 107, 107, 0.06);
    padding: 10px;
  }
`

export const LegendLink = styled.a`
  margin-left: 10px; 
  margin-right: 10px;
`

export const BookieNameBadge = styled.span`
  margin-bottom: 2px;
  text-transform: capitalize;
  padding: 3px;
  font-size: large;
    background-color: #B8DCEF;
    color: #1C2D1C;
    padding: 10px;

`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  background-color: #1C2D1C;
  border: 1px solid #3B4B39;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  color: #FFFFFF;
  font-size: 0.875rem;
  width: 250px;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:focus {
    outline: none;
    border-color: #1BB78A;
    box-shadow: 0 0 0 2px rgba(27, 183, 138, 0.2);
  }

  &::placeholder {
    color: #6B7A6B;
  }
`;

export const BookiesListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  width: 100%;
  padding: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0.5rem;
    gap: 1rem;
  }
`;