import React, { useEffect, useState } from "react";
import { NavBarMain, Bookie } from "..";
import { useUser } from "../../hooks";
import {
  BookieContainer, BookieHeaderText,
  BookiesHeaderContainer, BookiesListContainer, PageContainer,
  SearchContainer, SearchInput, TabsContainer, TabButton
} from './UserBookies.styled'
import { checkIfTokenStillValid } from "../../repositories/utils";
import Calculator from "./Calculator";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../contexts/UsersContext";

export const UserBookies = ({ history, match }) => {
    const { t } = useTranslation();
    const { bookieAction, getBookies } = useUser()
    const { mutate } = useUsers();
    const [activeBookies, setActiveBookies] = useState({});
    const [bookies, setBookies] = useState(null);
    const [activeTab, setActiveTab] = useState("available");
    const [searchQuery, setSearchQuery] = useState("");

    const refreshBookies = async () => {
      const updatedBookies = await getBookies(match.params.email, false);
      setBookies(updatedBookies);
    };

    useEffect(() => {
      refreshBookies();
      checkIfTokenStillValid(history)
    }, [match.params.email, mutate])

    const setBookieStatus = (bookie, enabledStatus) => {
      setActiveBookies({...activeBookies, [bookie]: enabledStatus});
    };

    const toggleBookieStatus = async (bookie, userEnabled) => {
      const action = userEnabled ? "disable" : "enable";
      await bookieAction(match.params.email, bookie, action)
      const newStatus = action === "enable";
      setBookieStatus(bookie, newStatus);
      await refreshBookies();
    };

    const filterBookies = (bookies) => {
      if (!bookies) return [];
      
      return bookies
        .filter(bookie => activeTab === "available" ? !bookie.inUse : bookie.inUse)
        .filter(bookie => {
          const searchTerm = searchQuery.toLowerCase();
          return bookie.name.toLowerCase().includes(searchTerm) ||
                 (bookie.provider && bookie.provider.toLowerCase().includes(searchTerm));
        });
    };

    const renderTabContent = () => {
      if (activeTab === "calculator") {
        return <Calculator />;
      }

      const filteredBookies = filterBookies(bookies);

      return (
        <BookieContainer>
          <BookiesHeaderContainer>
            <BookieHeaderText>
              {t(activeTab === "available" ? "availableBookies" : "bookiesInUse")}
            </BookieHeaderText>
            <SearchContainer>
              <SearchInput
                type="text"
                placeholder={t("searchBookies")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </SearchContainer>
          </BookiesHeaderContainer>
          <BookiesListContainer>
            {filteredBookies
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((bookie) => (
                <Bookie
                  key={bookie.id}
                  bookie={bookie}
                  toggleBookieStatus={toggleBookieStatus}
                  activeBookies={activeBookies}
                  bookies={bookies}
                  type={activeTab === "available" ? "add" : "edit"}
                  onRefresh={refreshBookies}
                />
              ))}
          </BookiesListContainer>
        </BookieContainer>
      );
    };

    return (
      <PageContainer>
        <NavBarMain currentPage="bothome" history={history}/>
        <TabsContainer>
          {["available", "inUse", "calculator"].map((tab) => (
            <TabButton
              key={tab}
              active={activeTab === tab}
              onClick={() => {
                setActiveTab(tab);
                setSearchQuery("");
              }}
            >
              {t(tab === "available" ? "availableBookies" : tab === "inUse" ? "bookiesInUse" : "calculator")}
            </TabButton>
          ))}
        </TabsContainer>
        {renderTabContent()}
      </PageContainer>
    );
  };
