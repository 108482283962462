import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  CloseButton,
  UsersList,
  UserItem,
  UserEmail,
  NoUsers,
  RemoveButton,
  SearchContainer,
  SearchInput,
  SearchResults,
  SearchResultItem
} from './ViewUsersModal.styled';
import { X, Search } from 'lucide-react';
import { toast } from 'react-toastify';
import { useUser } from '../../hooks';

const ViewUsersModal = ({ show, onClose, administrator }) => {
  const { t } = useTranslation();
  const { addUserToAdministrator, queryUsers, removeUserFromAdministrator } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (administrator?.users) {
      setUsers([...administrator.users].sort((a, b) => a.localeCompare(b)));
    }
  }, [administrator?.users]);

  if (!show) return null;

  const handleSearch = async (value) => {
    setSearchTerm(value);
    if (!value.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const results = await queryUsers(value);
      // Filter out users that are already added
      const filteredResults = results.filter(user => !users.includes(user._id.email));
      setSearchResults(filteredResults);
    } catch (error) {
      toast.error(t('errorSearchingUsers'));
    } finally {
      setIsSearching(false);
    }
  };

  const handleAddUser = async (userEmail) => {
    try {
      await addUserToAdministrator(administrator._id.email, userEmail);
      const updatedUsers = [...users, userEmail].sort((a, b) => a.localeCompare(b));
      setUsers(updatedUsers);
      setSearchTerm('');
      setSearchResults([]);
      toast.success(t('userAddedSuccessfully'));
    } catch (error) {
      toast.error(t('errorAddingUser'));
    }
  };

  const handleRemoveUser = async (userToRemove) => {
    try {
      await removeUserFromAdministrator(administrator._id.email, userToRemove);
      const updatedUsers = users.filter(user => user !== userToRemove);
      setUsers(updatedUsers);
      toast.success(t('userRemovedSuccessfully'));
    } catch (error) {
      toast.error(t('errorRemovingUser'));
    }
  };

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{t('administratorUsers')}</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <SearchContainer>
            <Search size={20} />
            <SearchInput
              type="text"
              placeholder={t('searchUsers')}
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </SearchContainer>

          {isSearching ? (
            <div>{t('searching')}</div>
          ) : searchResults.length > 0 && (
            <SearchResults>
              {searchResults.map((result) => (
                <SearchResultItem
                  key={result._id.email}
                  onClick={() => handleAddUser(result._id.email)}
                >
                  {result._id.email}
                </SearchResultItem>
              ))}
            </SearchResults>
          )}

          {users.length === 0 ? (
            <NoUsers>{t('noUsers')}</NoUsers>
          ) : (
            <UsersList>
              {users.map((user) => (
                <UserItem key={user}>
                  <UserEmail>{user}</UserEmail>
                  <RemoveButton onClick={() => handleRemoveUser(user)}>
                    <X size={16} />
                  </RemoveButton>
                </UserItem>
              ))}
            </UsersList>
          )}
        </ModalBody>
        <ModalFooter>
          <button onClick={onClose}>{t('close')}</button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewUsersModal; 